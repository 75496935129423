<template>
    <div class="landing-view">
        <BigHeader>
            <template v-slot:title>
                Займ на карту<br/>
                на любые цели
            </template>
        </BigHeader>
      <Footer v-if="isMark" />
      <div style="padding-bottom: 48px"></div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

import BigHeader from '@/components/common/big-header/BigHeader'

import Store from '@/store'

import comebacker from '@/mixins/comebacker'
import Footer from "@/components/common/footer/Footer.vue";

export default {
  data() {
    return {
      isMark: false
    }
  },
  name: 'App',
    mixins: [
        comebacker
    ],
    components: {
      Footer,
        BigHeader
    },
    beforeRouteEnter(to, from, next) {
        if (Cookies.get('sbg-cpa')) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'LoanPrimary' })
        }
    },
    mounted() {
      if (Cookies.get('sbg-cpa') === 'leadsuzs2') {
        this.isMark = true
      }
    }

};
</script>

<style lang="scss">
.landing-view {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
</style>